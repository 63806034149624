@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
@-moz-document url-prefix() {
  #header {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}
*,
::after,
::before {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

/* BEGIN CONTAINER */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-without-right-margin {
  width: 100%;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-without-left-margin {
  width: 100%;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .container-without-right-margin {
    width: auto;
    margin-left: calc((100vw - 540px) / 2);
  }

  .container-without-left-margin {
    width: auto;
    margin-right: calc((100vw - 540px) / 2);
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .container-without-right-margin {
    margin-left: calc((100vw - 720px) / 2);
  }

  .container-without-left-margin {
    margin-right: calc((100vw - 720px) / 2);
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .container-without-right-margin {
    margin-left: calc((100vw - 960px) / 2);
  }

  .container-without-left-margin {
    margin-right: calc((100vw - 960px) / 2);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .container-without-right-margin {
    margin-left: calc((100vw - 1140px) / 2);
  }

  .container-without-left-margin {
    margin-right: calc((100vw - 1140px) / 2);
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* END CONTAINER */

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

/* BEGIN DISPLAY  */

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
/* END DISPLAY */

/* BEGIN FLEX BOX */

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

/* END FLEX BOX */

/* BEGIN FLOAT */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

/* END FLOAT */

/* BEGIN OVERFLOW */
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

/* BEGIN OVERFLOW */

/* BEGIN POSITION */
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

/* END POSITION */

/* BEGIN SHADOW */
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}
/* END SHADOW */

/* BEGIN WIDTH */
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-100-px {
  width: 100px !important;
}

.w-200-px {
  width: 200px !important;
}

.w-300-px {
  width: 300px !important;
}

.w-400-px {
  width: 400px !important;
}

.w-500-px {
  width: 500px !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-100-px {
    width: 100px !important;
  }

  .w-sm-200-px {
    width: 200px !important;
  }

  .w-sm-300-px {
    width: 300px !important;
  }

  .w-sm-400-px {
    width: 400px !important;
  }

  .w-sm-500-px {
    width: 500px !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-100-px {
    width: 100px !important;
  }

  .w-md-200-px {
    width: 200px !important;
  }

  .w-md-300-px {
    width: 300px !important;
  }

  .w-md-400-px {
    width: 400px !important;
  }

  .w-md-500-px {
    width: 500px !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-100-px {
    width: 100px !important;
  }

  .w-lg-200-px {
    width: 200px !important;
  }

  .w-lg-300-px {
    width: 300px !important;
  }

  .w-lg-400-px {
    width: 400px !important;
  }

  .w-lg-500-px {
    width: 500px !important;
  }
}

@media (min-width: 1200px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-100-px {
    width: 100px !important;
  }

  .w-lg-200-px {
    width: 200px !important;
  }

  .w-lg-300-px {
    width: 300px !important;
  }

  .w-lg-400-px {
    width: 400px !important;
  }

  .w-lg-500-px {
    width: 500px !important;
  }
}

/* BEGIN WIDTH */

/* BEGIN HEIGHT */
.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-100-px {
  height: 100px !important;
}

.h-200-px {
  height: 200px !important;
}

.h-300-px {
  height: 300px !important;
}

.h-400-px {
  height: 400px !important;
}

.h-500-px {
  height: 500px !important;
}

@media (min-width: 576px) {
  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .h-sm-100-px {
    height: 100px !important;
  }

  .h-sm-200-px {
    height: 200px !important;
  }

  .h-sm-300-px {
    height: 300px !important;
  }

  .h-sm-400-px {
    height: 400px !important;
  }

  .h-sm-500-px {
    height: 500px !important;
  }
}

@media (min-width: 768px) {
  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .h-md-100-px {
    height: 100px !important;
  }

  .h-md-200-px {
    height: 200px !important;
  }

  .h-md-300-px {
    height: 300px !important;
  }

  .h-md-400-px {
    height: 400px !important;
  }

  .h-md-500-px {
    height: 500px !important;
  }
}

@media (min-width: 992px) {
  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .h-lg-100-px {
    height: 100px !important;
  }

  .h-lg-200-px {
    height: 200px !important;
  }

  .h-lg-300-px {
    height: 300px !important;
  }

  .h-lg-400-px {
    height: 400px !important;
  }

  .h-lg-500-px {
    height: 500px !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .h-xl-100-px {
    height: 100px !important;
  }

  .h-xl-200-px {
    height: 200px !important;
  }

  .h-xl-300-px {
    height: 300px !important;
  }

  .h-xl-400-px {
    height: 400px !important;
  }

  .h-xl-500-px {
    height: 500px !important;
  }
}

/* END HEIGHT */

/* BEGIN mix/max width and height */
.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

/* END mix/max width and height */

/* BEGIN HELPER MARGIN AND PADDING */

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

/* END HELPER MARGIN AND PADDING */

/* BEGIN HELPER TEXT */
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-pre-line {
  white-space: pre-line;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

.text-break-spaces {
  white-space: break-spaces;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.word-break-all {
  word-break: break-all !important;
}

.word-break-break-word {
  word-break: break-word;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-wrap {
    white-space: normal !important;
  }

  .text-sm-nowrap {
    white-space: nowrap !important;
  }

  .text-sm-pre-line {
    white-space: pre-line;
  }

  .word-wrap-sm-break-word {
    word-wrap: break-word;
  }

  .word-break-sm-break-word {
    word-break: break-word;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-wrap {
    white-space: normal !important;
  }

  .text-md-nowrap {
    white-space: nowrap !important;
  }

  .text-md-pre-line {
    white-space: pre-line;
  }

  .word-wrap-md-break-word {
    word-wrap: break-word;
  }

  .word-break-md-break-word {
    word-break: break-word;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-wrap {
    white-space: normal !important;
  }

  .text-lg-nowrap {
    white-space: nowrap !important;
  }

  .text-lg-pre-line {
    white-space: pre-line;
  }

  .word-wrap-lg-break-word {
    word-wrap: break-word;
  }

  .word-break-lg-break-word {
    word-break: break-word;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .text-xl-wrap {
    white-space: normal !important;
  }

  .text-xl-nowrap {
    white-space: nowrap !important;
  }

  .text-xl-pre-line {
    white-space: pre-line;
  }

  .word-wrap-xl-break-word {
    word-wrap: break-word;
  }

  .word-break-xl-break-word {
    word-break: break-word;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

/* END HELPER TEXT */

/* BEGIN ROUNDED */
.rounded {
  border-radius: 0.25rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}
/* END ROUNDED */

/* BEGIN BUTTON  */
.btn-special-select {
  .ant-btn-default.ant-btn-disabled {
    cursor: pointer;
  }

  .ant-btn-default.ant-btn-disabled:hover {
    background-color: var(--color-white);
  }
}
/* END BUTTON */

/* BEGIN HEADER CUSTOM  */
.custom-header {
  width: 100%;
  height: 62px;
  position: fixed;
  z-index: 95;
}
/* END HEADER CUSTOM */

/* BEGIN IMAGE  */
.object-fit-cover {
  object-fit: cover;
}
/* END IMAGE */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  line-height: 1.5;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #2c2c2c;
  color: var(--text-color);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

:root {
  --color-plano: #4dbcff;
  --color-primary: #009af6;
  --color-primary-dark: #0069a7;
  --color-secondary: #888888;
  --color-success: #2acc29;
  --color-warning: #fdb145;
  --color-danger: #fc393e;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #b0b0b0;
  --color-gray-light: #f8f8f8;
  --color-gray-medium: #e8e8e8;
  --color-tangerine: #e89300;
  --color-tangerine-yellow: #ffcc00;
  --color-white-gray: #fcfcfc;
  --color-gray-light: #f0f0f0;
  --color-cornflower: #94c2dd;
  --color-ghost-white: #fbfcff;
  --text-color: #2c2c2c;
  --text-color-light: #4c4c4c;
  --text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  --box-shadow-button: 0 2px 0 rgba(0, 0, 0, 0.045);
  --border-radius-input: 50rem;
  --color-secondary-light: #9a9a9a;
  --box-shadow-default: #2c2c2c29;
  --color-card-shadow: #2c2c2c29;
  --color-scrollbar: #c2c2c2;
  --color-white-transparent: #ffffff99;
  --color-black-transparent: #00000029;
  --color-image-filter: rgba(255, 255, 255, 0.25);
  --color-info: #f5fbff;
  --color-landing: #fbfbfb;
  --color-gray-transparent: #ffffff8c;
  --color-header-black: #131414;
  --color-black-gray: #1b1b1b;
  --color-solucao-digital: #e5005c;
  --color-consultoria: #f18700;
  --color-curso-online: #64358c;
  --color-sebraetec: #016ec7;
  --color-transparent-gray: rgba(44, 44, 44, 0.8);

  --color-btn-disabled-bg: #ececec;
  --color-btn-disabled-border: #dbdbdb;
  --color-btn-disabled-text: #adadad;

  --sebraetec-light-bg: #fafcff;
  --sebraetec-border: #d9d9d9;
  --sebraetec-text-accent: #58595a;
}

.powerzap_chat_canvas {
  -webkit-transform: translateZ(100000000000px);
          transform: translateZ(100000000000px);
}

/* BEGIN ROW */
.ant-row {
  zoom: 1;
  display: block;
  flex-flow: unset;
  position: relative;
}

.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}

.ant-row + .ant-row:before,
.ant-row:after {
  clear: both;
}

.ant-row:after,
.ant-row:before {
  display: table;
  content: '';
  clear: both;
}

.ant-row-flex + .ant-row-flex:before,
.ant-row-flex:after {
  clear: unset;
}

.ant-row-flex:after,
.ant-row-flex:before {
  display: unset;
  content: unset;
  clear: unset;
}
/* END ROW */

/* BEGIN COL */
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-xl-1,
.ant-col-xxl-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-xl-2,
.ant-col-xxl-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-xl-3,
.ant-col-xxl-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-xl-4,
.ant-col-xxl-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-xl-5,
.ant-col-xxl-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-xl-6,
.ant-col-xxl-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-xl-7,
.ant-col-xxl-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-xl-8,
.ant-col-xxl-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-xl-9,
.ant-col-xxl-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-xl-10,
.ant-col-xxl-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-xl-11,
.ant-col-xxl-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-xl-12,
.ant-col-xxl-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-xl-13,
.ant-col-xxl-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-xl-14,
.ant-col-xxl-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-xl-15,
.ant-col-xxl-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-xl-16,
.ant-col-xxl-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-xl-17,
.ant-col-xxl-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-xl-18,
.ant-col-xxl-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-xl-19,
.ant-col-xxl-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-xl-20,
.ant-col-xxl-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-xl-21,
.ant-col-xxl-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-xl-22,
.ant-col-xxl-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-xl-23,
.ant-col-xxl-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24,
.ant-col-xl-24,
.ant-col-xxl-24 {
  float: left;
  width: 100%;
}
/* END COL */

/* BEGIN GUTTER */
.ant-row[style*='row-gap: 8px']:not(.ant-row-flex) > * {
  padding: 4px 0;
}

.ant-row[style*='row-gap: 12px']:not(.ant-row-flex) > * {
  padding: 6px 0;
}

.ant-row[style*='row-gap: 16px']:not(.ant-row-flex) > * {
  padding: 8px 0;
}

.ant-row[style*='row-gap: 18px']:not(.ant-row-flex) > * {
  padding: 9px 0;
}

.ant-row[style*='row-gap: 20px']:not(.ant-row-flex) > * {
  padding: 10px 0;
}

.ant-row[style*='row-gap: 22px']:not(.ant-row-flex) > * {
  padding: 11px 0;
}

.ant-row[style*='row-gap: 24px']:not(.ant-row-flex) > * {
  padding: 12px 0;
}

.ant-row[style*='row-gap: 32px']:not(.ant-row-flex) > * {
  padding: 16px 0;
}

.ant-row[style*='row-gap: 48px']:not(.ant-row-flex) > * {
  padding: 24px 0;
}

.ant-row[style*='row-gap: 60px']:not(.ant-row-flex) > * {
  padding: 30px 0;
}
/* END GUTTER */

/* BEGIN BUTTONS */
.ant-btn {
  padding: 0 15px;
}

.ant-btn-secondary {
  color: #fff;
  color: var(--color-white);
  background-color: #888888;
  background-color: var(--color-secondary);
  border-color: #888888;
  border-color: var(--color-secondary);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  text-shadow: var(--text-shadow);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: var(--box-shadow-button);
}

.ant-btn-secondary:active {
  color: #fff;
  color: var(--color-white);
  background-color: #727070;
  border-color: #727070;
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #fff;
  color: var(--color-white);
  background-color: #979797;
  border-color: #979797;
}

[ant-click-animating-without-extra-node='true'].ant-btn-secondary {
  --antd-wave-shadow-color: #979797;
}

.ant-btn-background-ghost.ant-btn-secondary {
  color: #888888;
  color: var(--color-secondary);
  background-color: transparent;
  border-color: #888888;
  border-color: var(--color-secondary);
  text-shadow: none;
}

.ant-btn-success {
  color: #fff;
  color: var(--color-white);
  background-color: #2acc29;
  background-color: var(--color-success);
  border-color: #2acc29;
  border-color: var(--color-success);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  text-shadow: var(--text-shadow);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: var(--box-shadow-button);
}

.ant-btn-success:active {
  color: #fff;
  color: var(--color-white);
  background-color: rgb(55, 172, 55);
  border-color: rgb(55, 172, 55);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  color: var(--color-white);
  background-color: #1beb11;
  border-color: #1beb11;
}

[ant-click-animating-without-extra-node='true'].ant-btn-success {
  --antd-wave-shadow-color: var(--color-success);
}

.ant-btn-background-ghost.ant-btn-success {
  color: #2acc29;
  color: var(--color-success);
  background-color: transparent;
  border-color: #2acc29;
  border-color: var(--color-success);
  text-shadow: none;
}

.ant-btn-warning {
  color: #fff;
  color: var(--color-white);
  background-color: #fdb145;
  background-color: var(--color-warning);
  border-color: #fdb145;
  border-color: var(--color-warning);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  text-shadow: var(--text-shadow);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: var(--box-shadow-button);
}

.ant-btn-warning:active {
  color: #fff;
  color: var(--color-white);
  background-color: #be8636;
  border-color: #be8636;
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  color: var(--color-white);
  background-color: #ffbe55;
  border-color: #ffbe55;
}

[ant-click-animating-without-extra-node='true'].ant-btn-warning {
  --antd-wave-shadow-color: #be8636;
}

.ant-btn-ghost {
  color: #2c2c2c;
  background-color: transparent;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-warning {
  color: #ffbe55;
  background-color: transparent;
  border-color: #ffbe55;
  text-shadow: none;
}

.ant-btn.ant-btn-round.ant-btn-lg {
  -webkit-padding-start: 20px !important;
          padding-inline-start: 20px !important;
  -webkit-padding-end: 20px !important;
          padding-inline-end: 20px !important;
}

.wide-sm {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.wide {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.wide-lg {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.ant-btn-white:active {
  color: #fff;
  color: var(--color-white);
  background-color: #fff;
  background-color: var(--color-white);
  border-color: #fff;
  border-color: var(--color-white);
}

.ant-btn-white:hover,
.ant-btn-white:focus {
  color: #fff;
  color: var(--color-white);
  background-color: #fff;
  background-color: var(--color-white);
  border-color: #fff;
  border-color: var(--color-white);
}

[ant-click-animating-without-extra-node='true'].ant-btn-white {
  --antd-wave-shadow-color: var(--color-white);
}

.ant-btn-background-ghost.ant-btn-white {
  color: #fff;
  color: var(--color-white);
  background-color: transparent;
  border-color: #fff;
  border-color: var(--color-white);
  text-shadow: none;
}

.ant-btn:disabled {
  background-color: #ececec;
  background-color: var(--color-btn-disabled-bg);
  border-color: #dbdbdb;
  border-color: var(--color-btn-disabled-border);
  color: #adadad;
  color: var(--color-btn-disabled-text);
}

/* END BUTTONS */

/* BEGIN ICON */
.anticon {
  justify-content: center;
}
/* END ICON */

/* Begin Typography */
.ant-typography {
  word-break: unset;
}

h5.ant-typography {
  margin-bottom: 0.5em;
  color: #2c2c2c;
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.4;
}

h6.ant-typography {
  margin-bottom: 0.5em;
  color: #2c2c2c;
  color: var(--text-color);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
}

.ant-typography-success {
  color: #2acc29 !important;
  color: var(--color-success) !important;
}

.ant-typography-primary {
  color: #009af6 !important;
  color: var(--color-primary) !important;
}

.ant-typography-white {
  color: #fff !important;
  color: var(--color-white) !important;
}

.ant-typography-gray {
  color: #b0b0b0 !important;
  color: var(--color-gray) !important;
}
/* End Typography */

/* BEGIN Form */
.ant-form-explain {
  min-height: 0px;
  min-height: 20px;
  position: absolute;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #fc393e;
  color: var(--color-danger);
  background-color: #fff;
  z-index: 2;
  padding: 10px 15px;
  border-radius: 25px;
  width: 100%;
  box-shadow: 0px 0px 20px #00000033;
  margin-top: 6px;
  font-size: 12px;
}

.has-error .ant-form-explain::before {
  content: ' ';
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  left: 20px;
  border-left: 5px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}

.ant-form-item,
.ant-form-item-with-help {
  margin-bottom: 10px !important;
}

.ant-form-item-label label {
  font-weight: 300;
}

.ant-input-affix-wrapper:not(.ant-input-textarea-affix-wrapper),
.ant-select-selector,
.ant-input:not(textarea),
.ant-input-number,
.ant-picker,
.ant-select-selection,
.ant-time-picker-input {
  border-radius: 50rem !important;
  border-radius: var(--border-radius-input) !important;
}

.ant-input-group-addon:last-child {
  left: unset !important;
}

.ant-input-group-addon:last-child button {
  border-radius: 32px !important;
}

.ant-input-affix-wrapper:not(.ant-input-textarea-affix-wrapper) {
  height: 32px;
}

.ant-input-affix-wrapper-status-success:not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #2acc29;
  border-color: var(--color-success);
}

.wrap-label {
  padding-top: 4px;

  &.ant-form-item .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-item-label {
    white-space: normal;
    text-align: left;

    label {
      height: unset !important;
      padding-bottom: 3px;
    }
  }
}

/* BEGIN textarea */

textarea {
  border-radius: 19px !important;
}

textarea::-webkit-scrollbar {
  width: 3px;
}

textarea::-webkit-scrollbar-track {
  background: #eeeeee94;
}

textarea::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

textarea::-webkit-scrollbar-button {
  display: block;
}

textarea::-webkit-resizer {
  display: none;
}

.ant-form-item-control.has-feedback.has-success span textarea {
  border: 1px solid #2acc29 !important;
  border: 1px solid var(--color-success) !important;
}

/* END textarea */

.ant-select {
  width: 100%;
}

.ant-select--group-with-divider {
  .ant-select-item-group {
    min-height: 0;
  }

  .ant-divider {
    margin: 4px 0;
  }

  .ant-select-item-option-grouped {
    -webkit-padding-start: 12px;
            padding-inline-start: 12px;
  }
}

.ant-input,
.ant-input:focus,
.ant-input:hover,
.ant-select-selection,
.ant-cascader-picker {
  box-shadow: none !important;
}

.ant-form-item-control.has-feedback.has-success
  span
  .ant-select
  .ant-select-selection {
  border: 1px solid #2acc29 !important;
  border: 1px solid var(--color-success) !important;
}

.ant-form-item-control.has-feedback.has-success input[type='text'],
.ant-form-item-control.has-success input[type='text'] {
  border: 1px solid #2acc29 !important;
  border: 1px solid var(--color-success) !important;
}
/**
TODO: alteração dos icones do inputs não estão funcionando no firefox e safari,
rever a alteração do CSS.
.has-feedback.has-success .anticon.anticon-check-circle path {
  d: path(
    'M 912 190 h -69.9 c -9.8 0 -19.1 4.5 -25.1 12.2 L 404.7 724.5 L 207 474 a 32 32 0 0 0 -25.1 -12.2 H 112 c -6.7 0 -10.4 7.7 -6.3 12.9 l 273.9 347 c 12.8 16.2 37.4 16.2 50.3 0 l 488.4 -618.9 c 4.1 -5.1 0.4 -12.8 -6.3 -12.8 Z'
  );
}

.has-feedback.has-success .anticon.anticon-check-circle path {
  d: path(
    'M 912 190 h -69.9 c -9.8 0 -19.1 4.5 -25.1 12.2 L 404.7 724.5 L 207 474 a 32 32 0 0 0 -25.1 -12.2 H 112 c -6.7 0 -10.4 7.7 -6.3 12.9 l 273.9 347 c 12.8 16.2 37.4 16.2 50.3 0 l 488.4 -618.9 c 4.1 -5.1 0.4 -12.8 -6.3 -12.8 Z'
  );
}

.has-feedback.has-warning .anticon.anticon-exclamation-circle path {
  d: path(
    'M 448 804 a 64 64 0 1 0 128 0 a 64 64 0 1 0 -128 0 Z m 32 -168 h 64 c 4.4 0 8 -3.6 8 -8 V 164 c 0 -4.4 -3.6 -8 -8 -8 h -64 c -4.4 0 -8 3.6 -8 8 v 464 c 0 4.4 3.6 8 8 8 Z'
  );
}

.has-feedback.has-error .anticon.anticon-close-circle path {
  d: path(
    'M 563.8 512 l 262.5 -312.9 c 4.4 -5.2 0.7 -13.1 -6.1 -13.1 h -79.8 c -4.7 0 -9.2 2.1 -12.3 5.7 L 511.6 449.8 L 295.1 191.7 c -3 -3.6 -7.5 -5.7 -12.3 -5.7 H 203 c -6.8 0 -10.5 7.9 -6.1 13.1 L 459.4 512 L 196.9 824.9 A 7.95 7.95 0 0 0 203 838 h 79.8 c 4.7 0 9.2 -2.1 12.3 -5.7 l 216.5 -258.1 l 216.5 258.1 c 3 3.6 7.5 5.7 12.3 5.7 h 79.8 c 6.8 0 10.5 -7.9 6.1 -13.1 L 563.8 512 Z'
  );
}*/

/* BEGIN CAROUSEL */
.ant-carousel .slick-dots-bottom {
  display: block;
  text-align: center;
}

.slick-dots.slick-dots-bottom li {
  width: auto !important;
}
/* END CAROUSEL */

/* BEGIN INPUT NUMBER */

.ant-input-number-handler-wrap {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-input-number:focus {
  box-shadow: none;
}

.ant-input-number-handler:active {
  background: transparent;
}

.has-success
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-up:hover
  .ant-input-number-handler-up-inner,
.has-success
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-down:hover
  .ant-input-number-handler-down-inner {
  color: #2acc29;
  color: var(--color-success);
}

.has-success .ant-input-number {
  border-color: #2acc29 !important;
  border-color: var(--color-success) !important;
}

.has-warning
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-up:hover
  .ant-input-number-handler-up-inner,
.has-warning
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-down:hover
  .ant-input-number-handler-down-inner {
  color: #fdb145;
  color: var(--color-warning);
}

.has-warning .ant-input-number {
  border-color: #fdb145 !important;
  border-color: var(--color-warning) !important;
}

.has-warning .ant-input-number:focus {
  box-shadow: none;
}

.has-error
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-up:hover
  .ant-input-number-handler-up-inner,
.has-error
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-down:hover
  .ant-input-number-handler-down-inner {
  color: #fc393e;
  color: var(--color-danger);
}

.has-error .ant-input-number {
  border-color: #fc393e !important;
  border-color: var(--color-danger) !important;
}

.has-error .ant-input-number:focus {
  box-shadow: none;
}

/* END INPUT NUMBER */

/* BEGIN checkbox */

.ant-checkbox,
.ant-checkbox-group {
  display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  border-color: #2c2c2c;
  border-color: var(--text-color);
  background-color: #fff;
  background-color: var(--color-white);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 1px solid #24a224;
  border-top: 0;
  border-left: 0;
  top: 6px;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: transparent;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #2c2c2c;
  border-color: var(--text-color);
}

/* END checkbox */
/* BEGIN Radio */
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: #2c2c2c;
  background-color: var(--text-color);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #2c2c2c;
  border-color: var(--text-color);
  background-color: #fff;
  background-color: var(--color-white);
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(44, 44, 44, 0.08);
}

.ant-radio-wrapper:hover .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: #2c2c2c;
  border-color: var(--text-color);
}
/* END Radio */
/* END Form */

/* BEGIN Links */
.secondary-link {
  color: #888888;
  color: var(--color-secondary);
}

.success-link {
  color: #2acc29;
  color: var(--color-success);
}

.warning-link {
  color: #fdb145;
  color: var(--color-warning);
}

.danger-link {
  color: #fc393e;
  color: var(--color-danger);
}

/* END Links */

/* BEGIN BADGE */
.ant-badge.secondary .ant-badge-count {
  background: #888888;
  background: var(--color-secondary);
}

.ant-badge.primary .ant-badge-count {
  background: #009af6;
  background: var(--color-primary);
}

.ant-badge.success .ant-badge-count {
  background: #2acc29;
  background: var(--color-success);
}

.ant-badge.warning .ant-badge-count {
  background: #fdb145;
  background: var(--color-warning);
}

.ant-badge.danger .ant-badge-count {
  background: #fc393e;
  background: var(--color-danger);
}

.ant-tag {
  border-radius: 10px;
  font-size: 12px;
}

.ant-tag.secondary {
  color: #fff;
  color: var(--color-white);
  background: #888888;
  background: var(--color-secondary);
  border-color: #888888;
  border-color: var(--color-secondary);
}

.ant-tag.success {
  color: #fff;
  color: var(--color-white);
  background: #2acc29;
  background: var(--color-success);
  border-color: #2acc29;
  border-color: var(--color-success);
}

.ant-tag.primary {
  color: #fff;
  color: var(--color-white);
  background: #009af6;
  background: var(--color-primary);
  border-color: #009af6;
  border-color: var(--color-primary);
}

.ant-tag.warning {
  color: #fff;
  color: var(--color-white);
  background: #fdb145;
  background: var(--color-warning);
  border-color: #fdb145;
  border-color: var(--color-warning);
}

.ant-tag.danger {
  color: #fff;
  color: var(--color-white);
  background: #fc393e;
  background: var(--color-danger);
  border-color: #fc393e;
  border-color: var(--color-danger);
}

/* END BADGE */

/* BEGIN ALERTS */
.ant-alert-success {
  background-color: #2acc29;
  background-color: var(--color-success);
  border-color: #2acc29;
  border-color: var(--color-success);
}

.ant-alert-info {
  background-color: #009af6;
  background-color: var(--color-primary);
  border-color: #009af6;
  border-color: var(--color-primary);
}

.ant-alert-warning {
  background-color: #fdb145;
  background-color: var(--color-warning);
  border-color: #fdb145;
  border-color: var(--color-warning);
}

.ant-alert-error {
  background-color: #fc393e;
  background-color: var(--color-danger);
  border-color: #fc393e;
  border-color: var(--color-danger);
}

.ant-alert-icon {
  color: #fff !important;
  color: var(--color-white) !important;
}

.ant-alert-close-icon {
  font-size: 13px !important;
}

.ant-alert-with-description .ant-alert-message {
  color: #fff !important;
  color: var(--color-white) !important;
  border-radius: 0 !important;
  padding-right: 1rem !important;
  font-weight: 500 !important;
}

.ant-alert-with-description {
  color: #fff !important;
  color: var(--color-white) !important;
  border-radius: 0 !important;
}

.ant-alert-close-icon .anticon.anticon-close svg {
  color: #fff !important;
  color: var(--color-white) !important;
}

@media (min-width: 992px) {
  .ant-alert-with-description {
    display: flex !important;
  }
}

/* END ALERTS */

/* BEGIN TABS */
.ant-tabs-tab {
  padding: 12px 16px !important;
}
/* END TABS */

/* BEGIN Table */
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;
}

.ant-table-container th {
  background-color: #fff !important;
  background-color: var(--color-white) !important;
  font-weight: 500 !important;
}

.ant-table-container th::before {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > td {
  background-color: #fff !important;
  background-color: var(--color-white) !important;
  border-bottom: 1px solid #e8e8e8 !important;
  border-bottom: 1px solid var(--color-gray-medium) !important;
}

.ant-table-content {
  padding: 10px;
}

.ant-table-tbody tr:last-child td {
  border: unset;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
/* END Table */

/* BEGIN Pagination */
.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #fff !important;
  color: var(--color-white) !important;
}

.ant-pagination-item-active {
  border-radius: 50% !important;
}

.ant-pagination-item a {
  font-weight: 300;
}

.ant-pagination-item {
  border: unset;
  font-size: 20px;
  border-radius: 50% !important;
}
/* END PAGINATION  */

/* BEGIN modal */
.ant-modal {
  max-width: calc(100vw - 32px);
}

.ant-modal-mask {
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  background-color: #ffffff8c !important;
}

.ant-modal-close {
  position: absolute !important;
  inset-inline-end: -10px;
  top: -10px;
  width: 32px;
  height: 32px;
}

.ant-modal-close-x {
  width: unset !important;
  height: unset !important;
  line-height: unset !important;
}

.ant-modal-close-x .anticon {
  color: #fc393e !important;
  color: var(--color-danger) !important;
  font-size: 22px !important;
}

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-modal.zoom-leave {
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: unset;
  animation-fill-mode: unset;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-modal-footer {
  border-top: none !important;
  padding: 10px 16px;
}

.ant-modal-header {
  border-bottom: none !important;
  padding: 16px 24px;
  margin-bottom: 0 !important;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal .ant-modal-title {
  font-weight: 500;
}

.ant-modal-body {
  padding: 24px !important;
}
/* END modal */

/* BEGIN IMG */
img[type='large'] {
  width: 332px;
  height: 315px;
  object-fit: cover;
  object-position: center;
}

img[type='middle'] {
  width: 332px;
  height: 230px;
  object-fit: cover;
  object-position: center;
}

img[type='small'] {
  width: 260px;
  height: 224px;
  object-fit: cover;
  object-position: center;
}
/* END IMG */

/* BEGIN Step */
.step-ui-kit {
  width: 32px;
  height: 32px;
  border: 1px solid #b0b0b0;
  border: 1px solid var(--color-gray);
  border-radius: 50%;
}

.step-ui-kit::before {
  content: '';
  width: 24px;
  height: 24px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  background: var(--color-gray-light) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: block;
  margin-left: 3px;
  margin-top: 3px;
}

.steps-ui-kit .ant-steps-item {
  width: 33px;
}

.steps-ui-kit .ant-steps-item-process .step-ui-kit,
.steps-ui-kit .ant-steps-item-finish .step-ui-kit {
  border: 1px solid #2acc29;
  border: 1px solid var(--color-success);
}

.steps-ui-kit .ant-steps-item-process .step-ui-kit::before,
.steps-ui-kit .ant-steps-item-finish .step-ui-kit::before {
  background-color: #2acc29;
  background-color: var(--color-success);
}

.steps-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #2acc29;
  background-color: var(--color-success);
}

.steps-ui-kit .ant-steps-item-icon {
  margin: 0 !important;
  text-align: right !important;
  z-index: 1;
  position: relative;
}

.steps-ui-kit .ant-steps-item-title::after {
  left: 0px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  -webkit-padding-start: 0 !important;
          padding-inline-start: 0 !important;
  margin-right: 0;
}

.steps-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.steps-ui-kit
  .ant-steps-item-progress
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #2acc29;
  background-color: var(--color-success);
}

.steps-ui-kit .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 0px !important;
}

@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: flex;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    display: block;
  }

  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
  .ant-steps-item:last-child
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    display: none !important;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    width: 0;
  }
}
/* END STEP */

/* BEGIN STEP SMALL */
.step-small-ui-kit {
  width: 8px;
  height: 8px;
  border: 1px solid #b0b0b0;
  border: 1px solid var(--color-gray);
  background-color: #b0b0b0;
  background-color: var(--color-gray);
  border-radius: 50%;
}

.step-small-ui-kit::before {
  content: '';
  /* width: 24px;
  height: 24px;
  background: var(--color-gray-light) 0% 0% no-repeat padding-box;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  right: 4px; */
}

.steps-small-ui-kit .ant-steps-item {
  width: 10px;
}

.steps-small-ui-kit .ant-steps-item-process .step-small-ui-kit,
.steps-small-ui-kit .ant-steps-item-finish .step-small-ui-kit {
  border: 1px solid #2acc29;
  border: 1px solid var(--color-success);
}

.steps-small-ui-kit .ant-steps-item-process .step-small-ui-kit,
.steps-small-ui-kit .ant-steps-item-finish .step-small-ui-kit {
  background-color: #2acc29;
  background-color: var(--color-success);
}

.steps-small-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #2acc29;
  background-color: var(--color-success);
}

.steps-small-ui-kit .ant-steps-item-icon {
  margin: 0 !important;
  text-align: right !important;
  z-index: 1;
  position: relative;
}

.steps-small-ui-kit .ant-steps-item-title::after {
  left: 0px;
  top: 4px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
}

.steps-small-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.steps-small-ui-kit
  .ant-steps-item-progress
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #2acc29;
  background-color: var(--color-success);
}

.steps-small-ui-kit
  .ant-steps-item-custom
  .ant-steps-item-icon
  > .ant-steps-icon {
  font-size: 0px !important;
  left: 0px !important;
}
/* END STEP SMALL */

/* SKELETON */
.skeleton {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* SKELETON */

/* DROPDOWN */

.ant-dropdown-menu {
  border-radius: 20px !important;
  box-shadow: 0px 0px 12px #2c2c2c29;
}

.ant-dropdown-placement-bottomLeft .ant-dropdown-menu,
.ant-dropdown-placement-bottomCenter .ant-dropdown-menu,
.ant-dropdown-placement-bottomRight .ant-dropdown-menu {
  margin-top: 10px;
}

.ant-dropdown-placement-topLeft .ant-dropdown-menu,
.ant-dropdown-placement-topCenter .ant-dropdown-menu,
.ant-dropdown-placement-topRight .ant-dropdown-menu {
  margin-bottom: 10px;
}

.ant-dropdown-placement-bottomLeft .ant-dropdown-menu::before,
.ant-dropdown-placement-bottomCenter .ant-dropdown-menu::before,
.ant-dropdown-placement-bottomRight .ant-dropdown-menu::before {
  content: ' ';
  width: 0;
  height: 0;
  position: absolute;
  top: 0px;
  border-left: 9px solid white;
  border-right: 9px solid transparent;
  border-bottom: 13px solid white;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  box-shadow: -5px 5px 6px -4px rgba(0, 0, 0, 0.2);
}

.ant-dropdown-placement-topLeft .ant-dropdown-menu::before,
.ant-dropdown-placement-topCenter .ant-dropdown-menu::before,
.ant-dropdown-placement-topRight .ant-dropdown-menu::before {
  content: ' ';
  width: 0;
  height: 0;
  position: absolute;
  bottom: -13px;
  border-left: 9px solid white;
  border-right: 9px solid transparent;
  border-bottom: 13px solid white;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  box-shadow: -5px 5px 6px -4px rgba(0, 0, 0, 0.2);
}

.ant-dropdown-placement-topLeft .ant-dropdown-menu::before {
  left: 16px;
}

.ant-dropdown-placement-topCenter .ant-dropdown-menu::before {
  margin: 0 46%;
}

.ant-dropdown-placement-topRight .ant-dropdown-menu::before {
  right: 13px;
}

.ant-dropdown-placement-bottomLeft .ant-dropdown-menu::before {
  left: 31px;
}

.ant-dropdown-placement-bottomCenter .ant-dropdown-menu::before {
  margin: 0 54%;
}

.ant-dropdown-placement-bottomRight .ant-dropdown-menu::before {
  right: 0px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 2px 15px 2px 12px;
  margin: 7px;
  height: 34px;
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f0f0f0;
  background-color: var(--color-gray-light);
  border-radius: 25px;
  color: #2c2c2c;
}

.dropdown-menu-item-selected,
.dropdown-menu-item-selected:hover {
  color: #fcfcfc;
  color: var(--color-white-gray);
  background-color: #009af6;
  background-color: var(--color-primary);
  border-radius: 25px;
  box-shadow: 0px 3px 6px #00000029;
}

.avatar {
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  object-position: center;
  object-fit: cover;
  border-radius: 50px;
  margin-left: -10px;
}

/* /DROPDOWN */

/* BEGIN TOASTER */

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #009af6;
  background: var(--color-primary);
}
.Toastify__toast--success {
  background: #2acc29;
  background: var(--color-success);
}
.Toastify__toast--warning {
  background: #fdb145;
  background: var(--color-warning);
}
.Toastify__toast--error {
  background: #fc393e;
  background: var(--color-danger);
}

.Toastify__toast-container {
  margin-left: 0 !important;
  padding: 0 !important;
  top: 0 !important;
  left: 0 !important;
  margin-left: 0 !important;
}

.Toastify__toast:first-child {
  padding-top: 70px !important;
}

.Toastify__toast:first-child .Toastify__toast-body {
  display: flex;
  align-items: center;
}

.Toastify__toast {
  min-height: 48px;
  margin-bottom: 0 !important;
  padding: 8px 8px !important;
  font-size: 13px;
}

.Toastify__toast:first-child .Toastify__close-button {
  top: 70px;
}

.Toastify__close-button {
  color: #fff;
  font-family: Raleway !important;
  font-weight: 700;
  font-size: 10px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 1 !important;
  transition: 0.3s ease;
  align-self: flex-start;
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 576px) {
  .icon-toast {
    margin-right: 20px;
  }
}

/* END TOASTER */

