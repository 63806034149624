@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  line-height: 1.5;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: var(--text-color);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

:root {
  --color-plano: #4dbcff;
  --color-primary: #009af6;
  --color-primary-dark: #0069a7;
  --color-secondary: #888888;
  --color-success: #2acc29;
  --color-warning: #fdb145;
  --color-danger: #fc393e;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #b0b0b0;
  --color-gray-light: #f8f8f8;
  --color-gray-medium: #e8e8e8;
  --color-tangerine: #e89300;
  --color-tangerine-yellow: #ffcc00;
  --color-white-gray: #fcfcfc;
  --color-gray-light: #f0f0f0;
  --color-cornflower: #94c2dd;
  --color-ghost-white: #fbfcff;
  --text-color: #2c2c2c;
  --text-color-light: #4c4c4c;
  --text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  --box-shadow-button: 0 2px 0 rgba(0, 0, 0, 0.045);
  --border-radius-input: 50rem;
  --color-secondary-light: #9a9a9a;
  --box-shadow-default: #2c2c2c29;
  --color-card-shadow: #2c2c2c29;
  --color-scrollbar: #c2c2c2;
  --color-white-transparent: #ffffff99;
  --color-black-transparent: #00000029;
  --color-image-filter: rgba(255, 255, 255, 0.25);
  --color-info: #f5fbff;
  --color-landing: #fbfbfb;
  --color-gray-transparent: #ffffff8c;
  --color-header-black: #131414;
  --color-black-gray: #1b1b1b;
  --color-solucao-digital: #e5005c;
  --color-consultoria: #f18700;
  --color-curso-online: #64358c;
  --color-sebraetec: #016ec7;
  --color-transparent-gray: rgba(44, 44, 44, 0.8);

  --color-btn-disabled-bg: #ececec;
  --color-btn-disabled-border: #dbdbdb;
  --color-btn-disabled-text: #adadad;

  --sebraetec-light-bg: #fafcff;
  --sebraetec-border: #d9d9d9;
  --sebraetec-text-accent: #58595a;
}

.powerzap_chat_canvas {
  transform: translateZ(100000000000px);
}

/* BEGIN ROW */
.ant-row {
  zoom: 1;
  display: block;
  flex-flow: unset;
  position: relative;
}

.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}

.ant-row + .ant-row:before,
.ant-row:after {
  clear: both;
}

.ant-row:after,
.ant-row:before {
  display: table;
  content: '';
  clear: both;
}

.ant-row-flex + .ant-row-flex:before,
.ant-row-flex:after {
  clear: unset;
}

.ant-row-flex:after,
.ant-row-flex:before {
  display: unset;
  content: unset;
  clear: unset;
}
/* END ROW */

/* BEGIN COL */
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-xl-1,
.ant-col-xxl-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-xl-2,
.ant-col-xxl-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-xl-3,
.ant-col-xxl-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-xl-4,
.ant-col-xxl-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-xl-5,
.ant-col-xxl-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-xl-6,
.ant-col-xxl-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-xl-7,
.ant-col-xxl-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-xl-8,
.ant-col-xxl-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-xl-9,
.ant-col-xxl-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-xl-10,
.ant-col-xxl-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-xl-11,
.ant-col-xxl-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-xl-12,
.ant-col-xxl-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-xl-13,
.ant-col-xxl-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-xl-14,
.ant-col-xxl-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-xl-15,
.ant-col-xxl-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-xl-16,
.ant-col-xxl-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-xl-17,
.ant-col-xxl-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-xl-18,
.ant-col-xxl-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-xl-19,
.ant-col-xxl-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-xl-20,
.ant-col-xxl-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-xl-21,
.ant-col-xxl-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-xl-22,
.ant-col-xxl-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-xl-23,
.ant-col-xxl-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24,
.ant-col-xl-24,
.ant-col-xxl-24 {
  float: left;
  width: 100%;
}
/* END COL */

/* BEGIN GUTTER */
.ant-row[style*='row-gap: 8px']:not(.ant-row-flex) > * {
  padding: 4px 0;
}

.ant-row[style*='row-gap: 12px']:not(.ant-row-flex) > * {
  padding: 6px 0;
}

.ant-row[style*='row-gap: 16px']:not(.ant-row-flex) > * {
  padding: 8px 0;
}

.ant-row[style*='row-gap: 18px']:not(.ant-row-flex) > * {
  padding: 9px 0;
}

.ant-row[style*='row-gap: 20px']:not(.ant-row-flex) > * {
  padding: 10px 0;
}

.ant-row[style*='row-gap: 22px']:not(.ant-row-flex) > * {
  padding: 11px 0;
}

.ant-row[style*='row-gap: 24px']:not(.ant-row-flex) > * {
  padding: 12px 0;
}

.ant-row[style*='row-gap: 32px']:not(.ant-row-flex) > * {
  padding: 16px 0;
}

.ant-row[style*='row-gap: 48px']:not(.ant-row-flex) > * {
  padding: 24px 0;
}

.ant-row[style*='row-gap: 60px']:not(.ant-row-flex) > * {
  padding: 30px 0;
}
/* END GUTTER */

/* BEGIN BUTTONS */
.ant-btn {
  padding: 0 15px;
}

.ant-btn-secondary {
  color: var(--color-white);
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  text-shadow: var(--text-shadow);
  -webkit-box-shadow: var(--box-shadow-button);
  box-shadow: var(--box-shadow-button);
}

.ant-btn-secondary:active {
  color: var(--color-white);
  background-color: #727070;
  border-color: #727070;
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: var(--color-white);
  background-color: #979797;
  border-color: #979797;
}

[ant-click-animating-without-extra-node='true'].ant-btn-secondary {
  --antd-wave-shadow-color: #979797;
}

.ant-btn-background-ghost.ant-btn-secondary {
  color: var(--color-secondary);
  background-color: transparent;
  border-color: var(--color-secondary);
  text-shadow: none;
}

.ant-btn-success {
  color: var(--color-white);
  background-color: var(--color-success);
  border-color: var(--color-success);
  text-shadow: var(--text-shadow);
  -webkit-box-shadow: var(--box-shadow-button);
  box-shadow: var(--box-shadow-button);
}

.ant-btn-success:active {
  color: var(--color-white);
  background-color: rgb(55, 172, 55);
  border-color: rgb(55, 172, 55);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: var(--color-white);
  background-color: #1beb11;
  border-color: #1beb11;
}

[ant-click-animating-without-extra-node='true'].ant-btn-success {
  --antd-wave-shadow-color: var(--color-success);
}

.ant-btn-background-ghost.ant-btn-success {
  color: var(--color-success);
  background-color: transparent;
  border-color: var(--color-success);
  text-shadow: none;
}

.ant-btn-warning {
  color: var(--color-white);
  background-color: var(--color-warning);
  border-color: var(--color-warning);
  text-shadow: var(--text-shadow);
  -webkit-box-shadow: var(--box-shadow-button);
  box-shadow: var(--box-shadow-button);
}

.ant-btn-warning:active {
  color: var(--color-white);
  background-color: #be8636;
  border-color: #be8636;
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: var(--color-white);
  background-color: #ffbe55;
  border-color: #ffbe55;
}

[ant-click-animating-without-extra-node='true'].ant-btn-warning {
  --antd-wave-shadow-color: #be8636;
}

.ant-btn-ghost {
  color: #2c2c2c;
  background-color: transparent;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-warning {
  color: #ffbe55;
  background-color: transparent;
  border-color: #ffbe55;
  text-shadow: none;
}

.ant-btn.ant-btn-round.ant-btn-lg {
  padding-inline-start: 20px !important;
  padding-inline-end: 20px !important;
}

.wide-sm {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.wide {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.wide-lg {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.ant-btn-white:active {
  color: var(--color-white);
  background-color: var(--color-white);
  border-color: var(--color-white);
}

.ant-btn-white:hover,
.ant-btn-white:focus {
  color: var(--color-white);
  background-color: var(--color-white);
  border-color: var(--color-white);
}

[ant-click-animating-without-extra-node='true'].ant-btn-white {
  --antd-wave-shadow-color: var(--color-white);
}

.ant-btn-background-ghost.ant-btn-white {
  color: var(--color-white);
  background-color: transparent;
  border-color: var(--color-white);
  text-shadow: none;
}

.ant-btn:disabled {
  background-color: var(--color-btn-disabled-bg);
  border-color: var(--color-btn-disabled-border);
  color: var(--color-btn-disabled-text);
}

/* END BUTTONS */

/* BEGIN ICON */
.anticon {
  justify-content: center;
}
/* END ICON */

/* Begin Typography */
.ant-typography {
  word-break: unset;
}

h5.ant-typography {
  margin-bottom: 0.5em;
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.4;
}

h6.ant-typography {
  margin-bottom: 0.5em;
  color: var(--text-color);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
}

.ant-typography-success {
  color: var(--color-success) !important;
}

.ant-typography-primary {
  color: var(--color-primary) !important;
}

.ant-typography-white {
  color: var(--color-white) !important;
}

.ant-typography-gray {
  color: var(--color-gray) !important;
}
/* End Typography */

/* BEGIN Form */
.ant-form-explain {
  min-height: 0px;
  min-height: 20px;
  position: absolute;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: var(--color-danger);
  background-color: #fff;
  z-index: 2;
  padding: 10px 15px;
  border-radius: 25px;
  width: 100%;
  box-shadow: 0px 0px 20px #00000033;
  margin-top: 6px;
  font-size: 12px;
}

.has-error .ant-form-explain::before {
  content: ' ';
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  left: 20px;
  border-left: 5px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}

.ant-form-item,
.ant-form-item-with-help {
  margin-bottom: 10px !important;
}

.ant-form-item-label label {
  font-weight: 300;
}

.ant-input-affix-wrapper:not(.ant-input-textarea-affix-wrapper),
.ant-select-selector,
.ant-input:not(textarea),
.ant-input-number,
.ant-picker,
.ant-select-selection,
.ant-time-picker-input {
  border-radius: var(--border-radius-input) !important;
}

.ant-input-group-addon:last-child {
  left: unset !important;
}

.ant-input-group-addon:last-child button {
  border-radius: 32px !important;
}

.ant-input-affix-wrapper:not(.ant-input-textarea-affix-wrapper) {
  height: 32px;
}

.ant-input-affix-wrapper-status-success:not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: var(--color-success);
}

.wrap-label {
  padding-top: 4px;

  &.ant-form-item .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-item-label {
    white-space: normal;
    text-align: left;

    label {
      height: unset !important;
      padding-bottom: 3px;
    }
  }
}

/* BEGIN textarea */

textarea {
  border-radius: 19px !important;
}

textarea::-webkit-scrollbar {
  width: 3px;
}

textarea::-webkit-scrollbar-track {
  background: #eeeeee94;
}

textarea::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

textarea::-webkit-scrollbar-button {
  display: block;
}

textarea::-webkit-resizer {
  display: none;
}

.ant-form-item-control.has-feedback.has-success span textarea {
  border: 1px solid var(--color-success) !important;
}

/* END textarea */

.ant-select {
  width: 100%;
}

.ant-select--group-with-divider {
  .ant-select-item-group {
    min-height: 0;
  }

  .ant-divider {
    margin: 4px 0;
  }

  .ant-select-item-option-grouped {
    padding-inline-start: 12px;
  }
}

.ant-input,
.ant-input:focus,
.ant-input:hover,
.ant-select-selection,
.ant-cascader-picker {
  box-shadow: none !important;
}

.ant-form-item-control.has-feedback.has-success
  span
  .ant-select
  .ant-select-selection {
  border: 1px solid var(--color-success) !important;
}

.ant-form-item-control.has-feedback.has-success input[type='text'],
.ant-form-item-control.has-success input[type='text'] {
  border: 1px solid var(--color-success) !important;
}
/**
TODO: alteração dos icones do inputs não estão funcionando no firefox e safari,
rever a alteração do CSS.
.has-feedback.has-success .anticon.anticon-check-circle path {
  d: path(
    'M 912 190 h -69.9 c -9.8 0 -19.1 4.5 -25.1 12.2 L 404.7 724.5 L 207 474 a 32 32 0 0 0 -25.1 -12.2 H 112 c -6.7 0 -10.4 7.7 -6.3 12.9 l 273.9 347 c 12.8 16.2 37.4 16.2 50.3 0 l 488.4 -618.9 c 4.1 -5.1 0.4 -12.8 -6.3 -12.8 Z'
  );
}

.has-feedback.has-success .anticon.anticon-check-circle path {
  d: path(
    'M 912 190 h -69.9 c -9.8 0 -19.1 4.5 -25.1 12.2 L 404.7 724.5 L 207 474 a 32 32 0 0 0 -25.1 -12.2 H 112 c -6.7 0 -10.4 7.7 -6.3 12.9 l 273.9 347 c 12.8 16.2 37.4 16.2 50.3 0 l 488.4 -618.9 c 4.1 -5.1 0.4 -12.8 -6.3 -12.8 Z'
  );
}

.has-feedback.has-warning .anticon.anticon-exclamation-circle path {
  d: path(
    'M 448 804 a 64 64 0 1 0 128 0 a 64 64 0 1 0 -128 0 Z m 32 -168 h 64 c 4.4 0 8 -3.6 8 -8 V 164 c 0 -4.4 -3.6 -8 -8 -8 h -64 c -4.4 0 -8 3.6 -8 8 v 464 c 0 4.4 3.6 8 8 8 Z'
  );
}

.has-feedback.has-error .anticon.anticon-close-circle path {
  d: path(
    'M 563.8 512 l 262.5 -312.9 c 4.4 -5.2 0.7 -13.1 -6.1 -13.1 h -79.8 c -4.7 0 -9.2 2.1 -12.3 5.7 L 511.6 449.8 L 295.1 191.7 c -3 -3.6 -7.5 -5.7 -12.3 -5.7 H 203 c -6.8 0 -10.5 7.9 -6.1 13.1 L 459.4 512 L 196.9 824.9 A 7.95 7.95 0 0 0 203 838 h 79.8 c 4.7 0 9.2 -2.1 12.3 -5.7 l 216.5 -258.1 l 216.5 258.1 c 3 3.6 7.5 5.7 12.3 5.7 h 79.8 c 6.8 0 10.5 -7.9 6.1 -13.1 L 563.8 512 Z'
  );
}*/

/* BEGIN CAROUSEL */
.ant-carousel .slick-dots-bottom {
  display: block;
  text-align: center;
}

.slick-dots.slick-dots-bottom li {
  width: auto !important;
}
/* END CAROUSEL */

/* BEGIN INPUT NUMBER */

.ant-input-number-handler-wrap {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-input-number:focus {
  box-shadow: none;
}

.ant-input-number-handler:active {
  background: transparent;
}

.has-success
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-up:hover
  .ant-input-number-handler-up-inner,
.has-success
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-down:hover
  .ant-input-number-handler-down-inner {
  color: var(--color-success);
}

.has-success .ant-input-number {
  border-color: var(--color-success) !important;
}

.has-warning
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-up:hover
  .ant-input-number-handler-up-inner,
.has-warning
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-down:hover
  .ant-input-number-handler-down-inner {
  color: var(--color-warning);
}

.has-warning .ant-input-number {
  border-color: var(--color-warning) !important;
}

.has-warning .ant-input-number:focus {
  box-shadow: none;
}

.has-error
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-up:hover
  .ant-input-number-handler-up-inner,
.has-error
  .ant-input-number
  .ant-input-number-handler.ant-input-number-handler-down:hover
  .ant-input-number-handler-down-inner {
  color: var(--color-danger);
}

.has-error .ant-input-number {
  border-color: var(--color-danger) !important;
}

.has-error .ant-input-number:focus {
  box-shadow: none;
}

/* END INPUT NUMBER */

/* BEGIN checkbox */

.ant-checkbox,
.ant-checkbox-group {
  display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  border-color: var(--text-color);
  background-color: var(--color-white);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 1px solid #24a224;
  border-top: 0;
  border-left: 0;
  top: 6px;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: transparent;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--text-color);
}

/* END checkbox */
/* BEGIN Radio */
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: var(--text-color);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--text-color);
  background-color: var(--color-white);
}

.ant-radio-input:focus + .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px rgba(44, 44, 44, 0.08);
  box-shadow: 0 0 0 3px rgba(44, 44, 44, 0.08);
}

.ant-radio-wrapper:hover .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: var(--text-color);
}
/* END Radio */
/* END Form */

/* BEGIN Links */
.secondary-link {
  color: var(--color-secondary);
}

.success-link {
  color: var(--color-success);
}

.warning-link {
  color: var(--color-warning);
}

.danger-link {
  color: var(--color-danger);
}

/* END Links */

/* BEGIN BADGE */
.ant-badge.secondary .ant-badge-count {
  background: var(--color-secondary);
}

.ant-badge.primary .ant-badge-count {
  background: var(--color-primary);
}

.ant-badge.success .ant-badge-count {
  background: var(--color-success);
}

.ant-badge.warning .ant-badge-count {
  background: var(--color-warning);
}

.ant-badge.danger .ant-badge-count {
  background: var(--color-danger);
}

.ant-tag {
  border-radius: 10px;
  font-size: 12px;
}

.ant-tag.secondary {
  color: var(--color-white);
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.ant-tag.success {
  color: var(--color-white);
  background: var(--color-success);
  border-color: var(--color-success);
}

.ant-tag.primary {
  color: var(--color-white);
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.ant-tag.warning {
  color: var(--color-white);
  background: var(--color-warning);
  border-color: var(--color-warning);
}

.ant-tag.danger {
  color: var(--color-white);
  background: var(--color-danger);
  border-color: var(--color-danger);
}

/* END BADGE */

/* BEGIN ALERTS */
.ant-alert-success {
  background-color: var(--color-success);
  border-color: var(--color-success);
}

.ant-alert-info {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.ant-alert-warning {
  background-color: var(--color-warning);
  border-color: var(--color-warning);
}

.ant-alert-error {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
}

.ant-alert-icon {
  color: var(--color-white) !important;
}

.ant-alert-close-icon {
  font-size: 13px !important;
}

.ant-alert-with-description .ant-alert-message {
  color: var(--color-white) !important;
  border-radius: 0 !important;
  padding-right: 1rem !important;
  font-weight: 500 !important;
}

.ant-alert-with-description {
  color: var(--color-white) !important;
  border-radius: 0 !important;
}

.ant-alert-close-icon .anticon.anticon-close svg {
  color: var(--color-white) !important;
}

@media (min-width: 992px) {
  .ant-alert-with-description {
    display: flex !important;
  }
}

/* END ALERTS */

/* BEGIN TABS */
.ant-tabs-tab {
  padding: 12px 16px !important;
}
/* END TABS */

/* BEGIN Table */
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;
}

.ant-table-container th {
  background-color: var(--color-white) !important;
  font-weight: 500 !important;
}

.ant-table-container th::before {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > td {
  background-color: var(--color-white) !important;
  border-bottom: 1px solid var(--color-gray-medium) !important;
}

.ant-table-content {
  padding: 10px;
}

.ant-table-tbody tr:last-child td {
  border: unset;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
/* END Table */

/* BEGIN Pagination */
.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: var(--color-white) !important;
}

.ant-pagination-item-active {
  border-radius: 50% !important;
}

.ant-pagination-item a {
  font-weight: 300;
}

.ant-pagination-item {
  border: unset;
  font-size: 20px;
  border-radius: 50% !important;
}
/* END PAGINATION  */

/* BEGIN modal */
.ant-modal {
  max-width: calc(100vw - 32px);
}

.ant-modal-mask {
  backdrop-filter: blur(15px);
  background-color: #ffffff8c !important;
}

.ant-modal-close {
  position: absolute !important;
  inset-inline-end: -10px;
  top: -10px;
  width: 32px;
  height: 32px;
}

.ant-modal-close-x {
  width: unset !important;
  height: unset !important;
  line-height: unset !important;
}

.ant-modal-close-x .anticon {
  color: var(--color-danger) !important;
  font-size: 22px !important;
}

.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-modal.zoom-leave {
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: unset;
  animation-fill-mode: unset;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-modal-footer {
  border-top: none !important;
  padding: 10px 16px;
}

.ant-modal-header {
  border-bottom: none !important;
  padding: 16px 24px;
  margin-bottom: 0 !important;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal .ant-modal-title {
  font-weight: 500;
}

.ant-modal-body {
  padding: 24px !important;
}
/* END modal */

/* BEGIN IMG */
img[type='large'] {
  width: 332px;
  height: 315px;
  object-fit: cover;
  object-position: center;
}

img[type='middle'] {
  width: 332px;
  height: 230px;
  object-fit: cover;
  object-position: center;
}

img[type='small'] {
  width: 260px;
  height: 224px;
  object-fit: cover;
  object-position: center;
}
/* END IMG */

/* BEGIN Step */
.step-ui-kit {
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-gray);
  border-radius: 50%;
}

.step-ui-kit::before {
  content: '';
  width: 24px;
  height: 24px;
  background: var(--color-gray-light) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: block;
  margin-left: 3px;
  margin-top: 3px;
}

.steps-ui-kit .ant-steps-item {
  width: 33px;
}

.steps-ui-kit .ant-steps-item-process .step-ui-kit,
.steps-ui-kit .ant-steps-item-finish .step-ui-kit {
  border: 1px solid var(--color-success);
}

.steps-ui-kit .ant-steps-item-process .step-ui-kit::before,
.steps-ui-kit .ant-steps-item-finish .step-ui-kit::before {
  background-color: var(--color-success);
}

.steps-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--color-success);
}

.steps-ui-kit .ant-steps-item-icon {
  margin: 0 !important;
  text-align: right !important;
  z-index: 1;
  position: relative;
}

.steps-ui-kit .ant-steps-item-title::after {
  left: 0px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-inline-start: 0 !important;
  margin-right: 0;
}

.steps-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.steps-ui-kit
  .ant-steps-item-progress
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-success);
}

.steps-ui-kit .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 0px !important;
}

@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: flex;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    display: block;
  }

  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
  .ant-steps-item:last-child
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    display: none !important;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    width: 0;
  }
}
/* END STEP */

/* BEGIN STEP SMALL */
.step-small-ui-kit {
  width: 8px;
  height: 8px;
  border: 1px solid var(--color-gray);
  background-color: var(--color-gray);
  border-radius: 50%;
}

.step-small-ui-kit::before {
  content: '';
  /* width: 24px;
  height: 24px;
  background: var(--color-gray-light) 0% 0% no-repeat padding-box;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  right: 4px; */
}

.steps-small-ui-kit .ant-steps-item {
  width: 10px;
}

.steps-small-ui-kit .ant-steps-item-process .step-small-ui-kit,
.steps-small-ui-kit .ant-steps-item-finish .step-small-ui-kit {
  border: 1px solid var(--color-success);
}

.steps-small-ui-kit .ant-steps-item-process .step-small-ui-kit,
.steps-small-ui-kit .ant-steps-item-finish .step-small-ui-kit {
  background-color: var(--color-success);
}

.steps-small-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--color-success);
}

.steps-small-ui-kit .ant-steps-item-icon {
  margin: 0 !important;
  text-align: right !important;
  z-index: 1;
  position: relative;
}

.steps-small-ui-kit .ant-steps-item-title::after {
  left: 0px;
  top: 4px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
}

.steps-small-ui-kit
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.steps-small-ui-kit
  .ant-steps-item-progress
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-success);
}

.steps-small-ui-kit
  .ant-steps-item-custom
  .ant-steps-item-icon
  > .ant-steps-icon {
  font-size: 0px !important;
  left: 0px !important;
}
/* END STEP SMALL */

/* SKELETON */
.skeleton {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #f2f2f2),
    color-stop(37%, #e6e6e6),
    color-stop(63%, #f2f2f2)
  );
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* SKELETON */

/* DROPDOWN */

.ant-dropdown-menu {
  border-radius: 20px !important;
  -webkit-box-shadow: 0px 0px 12px #2c2c2c29;
  box-shadow: 0px 0px 12px #2c2c2c29;
}

.ant-dropdown-placement-bottomLeft .ant-dropdown-menu,
.ant-dropdown-placement-bottomCenter .ant-dropdown-menu,
.ant-dropdown-placement-bottomRight .ant-dropdown-menu {
  margin-top: 10px;
}

.ant-dropdown-placement-topLeft .ant-dropdown-menu,
.ant-dropdown-placement-topCenter .ant-dropdown-menu,
.ant-dropdown-placement-topRight .ant-dropdown-menu {
  margin-bottom: 10px;
}

.ant-dropdown-placement-bottomLeft .ant-dropdown-menu::before,
.ant-dropdown-placement-bottomCenter .ant-dropdown-menu::before,
.ant-dropdown-placement-bottomRight .ant-dropdown-menu::before {
  content: ' ';
  width: 0;
  height: 0;
  position: absolute;
  top: 0px;
  border-left: 9px solid white;
  border-right: 9px solid transparent;
  border-bottom: 13px solid white;
  transform-origin: 0 0;
  transform: rotate(135deg);
  -moz-box-shadow: -5px 5px 6px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -5px 5px 6px -4px rgba(0, 0, 0, 0.2);
}

.ant-dropdown-placement-topLeft .ant-dropdown-menu::before,
.ant-dropdown-placement-topCenter .ant-dropdown-menu::before,
.ant-dropdown-placement-topRight .ant-dropdown-menu::before {
  content: ' ';
  width: 0;
  height: 0;
  position: absolute;
  bottom: -13px;
  border-left: 9px solid white;
  border-right: 9px solid transparent;
  border-bottom: 13px solid white;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  -moz-box-shadow: -5px 5px 6px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -5px 5px 6px -4px rgba(0, 0, 0, 0.2);
}

.ant-dropdown-placement-topLeft .ant-dropdown-menu::before {
  left: 16px;
}

.ant-dropdown-placement-topCenter .ant-dropdown-menu::before {
  margin: 0 46%;
}

.ant-dropdown-placement-topRight .ant-dropdown-menu::before {
  right: 13px;
}

.ant-dropdown-placement-bottomLeft .ant-dropdown-menu::before {
  left: 31px;
}

.ant-dropdown-placement-bottomCenter .ant-dropdown-menu::before {
  margin: 0 54%;
}

.ant-dropdown-placement-bottomRight .ant-dropdown-menu::before {
  right: 0px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 2px 15px 2px 12px;
  margin: 7px;
  height: 34px;
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--color-gray-light);
  border-radius: 25px;
  color: #2c2c2c;
}

.dropdown-menu-item-selected,
.dropdown-menu-item-selected:hover {
  color: var(--color-white-gray);
  background-color: var(--color-primary);
  border-radius: 25px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
}

.avatar {
  width: 30px;
  height: 30px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  object-position: center;
  object-fit: cover;
  border-radius: 50px;
  margin-left: -10px;
}

/* /DROPDOWN */

/* BEGIN TOASTER */

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: var(--color-primary);
}
.Toastify__toast--success {
  background: var(--color-success);
}
.Toastify__toast--warning {
  background: var(--color-warning);
}
.Toastify__toast--error {
  background: var(--color-danger);
}

.Toastify__toast-container {
  margin-left: 0 !important;
  padding: 0 !important;
  top: 0 !important;
  left: 0 !important;
  margin-left: 0 !important;
}

.Toastify__toast:first-child {
  padding-top: 70px !important;
}

.Toastify__toast:first-child .Toastify__toast-body {
  display: flex;
  align-items: center;
}

.Toastify__toast {
  min-height: 48px;
  margin-bottom: 0 !important;
  padding: 8px 8px !important;
  font-size: 13px;
}

.Toastify__toast:first-child .Toastify__close-button {
  top: 70px;
}

.Toastify__close-button {
  color: #fff;
  font-family: Raleway !important;
  font-weight: 700;
  font-size: 10px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 1 !important;
  transition: 0.3s ease;
  align-self: flex-start;
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 576px) {
  .icon-toast {
    margin-right: 20px;
  }
}

/* END TOASTER */
